import React, {Component} from "react"
import * as styles from './Index.module.scss';
import logo from './logo.png';

class BrewGiOhGame extends Component {

  render() {
   return (
     <div className={styles.container}>
       <section className={styles.section}>
         <div className={[styles.sectionItem, styles.logoContainer].join(' ')}>
           <div className={styles.logo}>
             <img src={logo} height={120}/>
           </div>
         </div>
         <div className={[styles.sectionItem, styles.centeredContent].join(' ')}>
           <div><h1>{'Yu-Gi-Oh drinking game'}</h1></div>
	         <div><h2>{'AKA Brew-Gi-Oh'}</h2></div>
         </div>
         <div className={styles.sectionItem}>
           {/*enter the shadow realm*/}
         </div>
       </section>
       <section className={styles.section}>
         <div className={styles.sectionItem}></div>
         <div className={[styles.sectionItem, styles.centeredContent].join('')}>
	         <h2>{'1 drink every time'}</h2>
	         <ul>
	           <li>{'Yugi transforms into Yami Yugi'}</li>
             <li>{'Kunai with chain is played'}</li>
             <li>{'Monster reborn'}</li>
             <li>{'Beaver warrior dies'}</li>
             <li>{'Pegasus uses his eye'}</li>
             <li>{'Toon cards'}</li>
             <li>{'Shadow realm entered'}</li>
             <li>{'Yugi remembers his grandpa'}</li>
             <li>{'Someone utilizes heart of the cards'}</li>
	         </ul>
           <h2>{'2 drinks every time'}</h2>
           <ul>
             <li>{'Dark magician appears'}</li>
             <li>{'Blue eyes white dragon appears'}</li>
             <li>{'Summoned skull'}</li>
             <li>{'Grandpa makes contact'}</li>
           </ul>
          <h2>{'Finish your drink when'}</h2>
          <ul>
            <li>{'A new millenium item is revealed'}</li>
            <li>{'To be continued...'}</li>
          </ul>
          <h2>{'Give out a drink each time an enemy monster dies'}</h2>
          <h2>{"Take a drink when of the heroes' monsters dies"}</h2>
	       </div>
	    <div className={styles.sectionItem}></div>
       </section>
     </div>
    );
  }

};

export default BrewGiOhGame;
